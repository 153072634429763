import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import BlogList from './components/BlogList';
import BlogPost from './components/BlogPost';
import ProjectList from './components/ProjectList';
import ProjectDetail from './components/ProjectDetail';
import Footer from './components/Footer';
import { projects } from './utils/projects';
import { blogPosts as posts } from './utils/blogPosts';
import { ThemeProvider } from './context/ThemeContext';

function App() {
  return (
    <ThemeProvider>
      <Router>
        <div className="flex flex-col min-h-screen bg-white dark:bg-gray-900 transition-colors duration-200">
          <Navbar />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/blog" element={<BlogList posts={posts} />} />
              <Route path="/blog/:slug" element={<BlogPost posts={posts} />} />
              <Route path="/projects" element={<ProjectList projects={projects} />} />
              <Route path="/projects/:slug" element={<ProjectDetail projects={projects} />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
