import React from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function BlogPost({ posts }) {
  const { slug } = useParams();
  const post = posts.find((p) => p.slug === slug);

  if (!post) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
        <div className="max-w-4xl mx-auto px-4">
          <Link to="/blog" className="text-blue-400 hover:underline mb-8 inline-block">
            ← Back to Blog
          </Link>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white">Post not found</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-0 dark:bg-gray-900 pt-20">
      <div className="max-w-4xl mx-auto px-4">
        <Link to="/blog" className="text-blue-400 hover:underline mb-8 inline-block">
          ← Back to Blog
        </Link>
        <article className="prose prose-gray dark:prose-invert max-w-none">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">{post.title}</h1>
          <p className="text-gray-500 dark:text-gray-400 mb-8">{post.date}</p>
          <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm">
            {/* <ReactMarkdown remarkPlugins={[remarkGfm]}>{post.content}</ReactMarkdown>
             */}
             {/* start from third line to the end  of psot.content */}
             <ReactMarkdown remarkPlugins={[remarkGfm]}>
               {post.content.split('\n').slice(3).join('\n')}
             </ReactMarkdown>
             
          </div>
        </article>
      </div>
    </div>
  );
}

export default BlogPost;
