import React from 'react';
import { Link } from 'react-router-dom';
import { FaGithub, FaLinkedin, FaExternalLinkAlt } from 'react-icons/fa';
import profilePic from '../assets/profile.png';
import { projects } from '../utils/projects';
import { blogPosts } from '../utils/blogPosts';

function Home() {
  const featuredProjects = projects.filter(project => project.status === 'Live' || project.status === 'In Development' || project.status === 'Done').slice(0, 3);
  const latestPosts = blogPosts.slice(0, 3);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      {/* Hero Section */}
      <section className="min-h-[80vh] flex items-center justify-center relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-100 to-gray-50 dark:from-gray-900/30 dark:to-gray-900" />
        
        <div className="max-w-6xl mx-auto px-4 relative">
          <div className="flex flex-col md:flex-row items-center gap-16 py-20">
            <div className="relative group">
              <div className="w-64 h-64 md:w-96 md:h-96">
                <div className="absolute inset-0 bg-gradient-to-r from-gray-200/50 to-gray-300/50 dark:from-blue-500/20 dark:to-purple-500/20 rounded-full blur-xl group-hover:blur-2xl transition-all duration-500" />
                  <img
                  src={profilePic}
                  alt="Joneskim"
                  className="rounded-full w-full h-full object-cover border-4 border-gray-200 dark:border-blue-500/20 shadow-2xl relative z-10 group-hover:scale-[1.02] transition-transform duration-500 filter dark:grayscale brightness-{-50}"
                  />
                <div className="absolute inset-0 rounded-full bg-gradient-to-r from-gray-200/30 to-gray-300/30 dark:from-blue-500/10 dark:to-purple-500/10 z-20 pointer-events-none group-hover:from-gray-300/40 group-hover:to-gray-300/40 dark:group-hover:from-blue-400/20 dark:group-hover:to-purple-400/20 transition-all duration-500" />
              </div>
            </div>
            
            <div className="flex-1 text-center md:text-left">
              <div className="space-y-6">
                <h1 className="text-5xl md:text-7xl font-bold">
                  <span className="text-gray-900 dark:text-blue-400">Joneskim</span>{' '}
                  <span className="text-gray-900/80 dark:text-blue-400/80">Kimo</span>
                </h1>

                <p className="text-xl text-gray-900 dark:text-gray-300 max-w-2xl leading-relaxed">
                  Hey! I'm Joneskim, a Physics and Computer Science student at the University of Chicago. 
                  Super interested in Physics, Scientific Computing, Distributed Systems, and AI.
                </p>

                <div className="flex items-center justify-center md:justify-start space-x-8 pt-6">
                  <a
                    href="https://github.com/joneskim"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-3 text-gray-900 dark:text-gray-400 hover:text-gray-800 dark:hover:text-blue-400 transition-colors group"
                    aria-label="GitHub Profile"
                  >
                    <FaGithub size={32} className="group-hover:scale-110 transition-transform duration-200" />
                    <span className="text-lg">GitHub</span>
                  </a>
                  <a
                    href="https://linkedin.com/in/joneskim"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-3 text-gray-900 dark:text-gray-400 hover:text-gray-800 dark:hover:text-blue-400 transition-colors group"
                    aria-label="LinkedIn Profile"
                  >
                    <FaLinkedin size={32} className="group-hover:scale-110 transition-transform duration-200" />
                    <span className="text-lg">LinkedIn</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Projects Section */}
      <section className="py-16 bg-white dark:bg-gray-900">
        <div className="max-w-4xl mx-auto px-4">
          <div className="flex justify-between items-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white">Featured Projects</h2>
            <Link
              to="/projects"
              className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-blue-400 transition-colors"
            >
              View All →
            </Link>
          </div>
          <div className="grid gap-8">
            {featuredProjects.map((project) => (
              <Link
                key={project.slug}
                to={`/projects/${project.slug}`}
                className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm hover:shadow-md dark:shadow-none dark:hover:bg-gray-700/80 transition-all duration-200"
              >
                <div className="flex flex-wrap justify-between items-start gap-4 mb-4">
                  <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                    {project.title}
                  </h3>
                  <span
                    className={`px-3 py-1 rounded-full text-sm ${
                      project.status === 'Live'
                        ? 'bg-green-500/20 text-green-400'
                        : 'bg-yellow-500/20 text-yellow-400'
                    }`}
                  >
                    {project.status}
                  </span>
                </div>
                <p className="text-gray-600 dark:text-gray-300 mb-6">{project.description}</p>
                <div className="flex flex-wrap items-center justify-between gap-4">
                  <div className="flex flex-wrap gap-2">
                    {project.technologies.slice(0, 4).map((tech) => (
                      <span
                        key={tech}
                        className="bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 px-3 py-1 rounded-full text-sm"
                      >
                        {tech}
                      </span>
                    ))}
                    {project.technologies.length > 4 && (
                      <span className="bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 px-3 py-1 rounded-full text-sm">
                        +{project.technologies.length - 4}
                      </span>
                    )}
                  </div>
                  <div className="flex items-center space-x-4">
                    {project.links.github && (
                      <a
                        href={project.links.github}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-blue-400 transition-colors"
                        aria-label="GitHub Repository"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <FaGithub size={20} />
                      </a>
                    )}
                    {project.links.live && (
                      <a
                        href={project.links.live}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-blue-400 transition-colors"
                        aria-label="Live Demo"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <FaExternalLinkAlt size={20} />
                      </a>
                    )}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Latest Blog Posts Section */}
      <section className="py-16 bg-white dark:bg-gray-900">
        <div className="max-w-4xl mx-auto px-4">
          <div className="flex justify-between items-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white">Latest Posts</h2>
            <Link
              to="/blog"
              className="text-gray-600 dark:text-gray-300 hover:text-gray-800 dark:hover:text-blue-400 transition-colors"
            >
              View All →
            </Link>
          </div>
          <div className="grid gap-8">
            {latestPosts.map((post) => (
              <Link
                key={post.slug}
                to={`/blog/${post.slug}`}
                className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-sm hover:shadow-md dark:shadow-none dark:hover:bg-gray-700/80 transition-all duration-200"
              >
                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                  {post.title}
                </h3>
                <p className="text-gray-500 dark:text-gray-400 mb-3">{post.date}</p>
                <p className="text-gray-600 dark:text-gray-300">{post.excerpt}</p>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
