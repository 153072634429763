import React from 'react';
import { Link } from 'react-router-dom';

function BlogList({ posts }) {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-20">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-8">Blog Posts</h1>
        <div className="grid gap-6">
          {posts.map((post) => (
            <Link
              key={post.slug}
              to={`/blog/${post.slug}`}
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm hover:shadow-md dark:hover:bg-gray-700/80 transition-all duration-200"
            >
              <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                {post.title}
              </h2>
              <p className="text-gray-500 dark:text-gray-400 mb-3">{post.date}</p>
              <p className="text-gray-600 dark:text-gray-300">{post.excerpt}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogList;
